import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { useResponsive } from "../Components/responsive";

const Layout = ({ children }) => {
  const { isXsm, isSm } = useResponsive();

  return (
    <LayoutContainer>
      <Header />
      <ContentWrapper $isSmall={isXsm || isSm}>
        <MainContent>{children}</MainContent>
      </ContentWrapper>
      <Footer />
    </LayoutContainer>
  );
};

const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh; /* Ensure it spans full viewport height */
`;

const LayoutContainer = styled.div`
  width: 100%; /* Ensures full viewport width */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  margin: 0; /* Temporarily remove margin to test height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Add height */
`;

export default Layout;
