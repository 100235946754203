import React from "react";
import HomeContentDesktop from "./HomeContentDesktop";
import SplashVideo from "./SplashVideo";
import Layout from "../../Layout/Layout";

function Home() {
  return (
    <Layout>
      <SplashVideo />

      <HomeContentDesktop />
    </Layout>
  );
}

export default Home;
