// SplashVideo.js
import React from "react";
import styled from "styled-components";
import { useResponsive } from "../../Components/responsive";
import LogoFull from "../../Components/LogoFull";

const SplashVideo = () => {
  const { isXsm, isSm } = useResponsive();
  const videoSrc =
    isXsm || isSm ? "/videos/splashMobile.mp4" : "/videos/splash.mp4";

  // Variables controlling vertical positions (as a percentage of the viewport height)
  const logoTop = isXsm || isSm ? "15vh" : "30vh";
  const textTop = isXsm || isSm ? "75vh" : "80vh";

  // Variable controlling the scale of the SVG logo
  const logoScale = isXsm || isSm ? 1.5 : 1.5;

  return (
    <SplashContainer>
      <VideoWrapper isXsm={isXsm}>
        <StyledVideo playsInline autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoWrapper>
      <LogoAbsolute top={logoTop}>
        <LogoFull scale={logoScale} />
      </LogoAbsolute>
      <TextAbsolute top={textTop}>
        <StyledH2 isXsm={isXsm} isSm={isSm}>
          creative VFX workflows
        </StyledH2>
      </TextAbsolute>
    </SplashContainer>
  );
};

export default SplashVideo;

// Styled Components

const SplashContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: black;
`;

const VideoWrapper = styled.div`
  width: ${(props) =>
    props.isXsm ? "100%" : "80%"}; /* 100% on xsm, otherwise 80% */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

// Absolute positioning for the logo and text
const LogoAbsolute = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const TextAbsolute = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const StyledH2 = styled.h2`
  font-size: ${(props) =>
    props.isXsm ? "1.4rem" : props.isSm ? "1.5rem" : "2rem"};
  font-weight: 200;
  background: linear-gradient(180deg, #808080 0%, #404040 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% auto;
  white-space: nowrap; /* Prevent text wrapping */
`;
